import { json, LinksFunction, LoaderFunction } from '@remix-run/node';
import { Link, Outlet } from '@remix-run/react';
import { useLocation } from 'react-router-dom';
import styles from '@assets/css/client-common.css?url';
import login_logo from '@assets/images/login_logo.png';
import MainLoadingSpinner from '@components/MainLoadingSpinner';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export const loader: LoaderFunction = async ({ request }) => {
    return json({});
};

export default function AuthLayout() {
    const { pathname } = useLocation();

    return (
        <>
            <div className="login_con">
                <div className="left">
                    <div className="in">
                        <div className="login_logo">
                            <p className="welcome">WELCOME TO</p>
                            <div className="logo">
                                <img className="comImg" src={login_logo} alt="" />
                            </div>
                            <p className="copy">Log in access the dashboard</p>
                        </div>
                        <p className="ref">Copyright 2023 aunionAI Corp. All Rights Reserved.</p>
                    </div>
                </div>
                <div className="right">
                    <div className="in">
                        <div className="login_box">
                            {(pathname === '/login' || pathname === '/signup') && (
                                <div className="login_cate">
                                    <Link to="/login" className={`unit gmt ${pathname === '/login' ? 'act' : ''}`}>
                                        Log in
                                    </Link>
                                    <Link to="/signup" className={`unit gmt ${pathname === '/signup' ? 'act' : ''}`}>
                                        Sign up
                                    </Link>
                                </div>
                            )}

                            {pathname === '/pw-confirm' && (
                                <div className="login_cate pw_setting_cate">
                                    <Link to="#" className="unit gmt act">
                                        Forgot your password?
                                    </Link>
                                </div>
                            )}

                            {pathname === '/pw-setting' && (
                                <div className="login_cate pw_setting_cate">
                                    <Link to="#" className="unit gmt act">
                                        Reset Password
                                    </Link>
                                </div>
                            )}

                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>

            <MainLoadingSpinner />
        </>
    );
}
